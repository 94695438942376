<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-vip-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">گروه VIP</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ group.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ group.gid }}</div>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mb-5">
        <div class="text-lg font-bold">
          <img
            src="@/assets/images/icons8-membership-card-96.png"
            class="inline-block h-20"
          />
          فعال سازی حالت VIP
        </div>
        <!-- <div class="text-lg font-bold text-center text-sky-600 mt-3">
          https://t.me/{{ group.bot.username }}?start=group_{{ group.gid }}
        </div> -->
        <p class="mt-5 text-xl text-red-600">
          <b>توجه:</b>
          قبل از فعال سازی حالت VIP مطمئن شوید که می‌دانید چکار می‌کنید و موارد زیر را با
          دقت بخوانید
        </p>
        <ul class="list-inside list-disc mt-5">
          <li>
            حالت VIP برای گروه هایی مناسب است که میخواهند از کاربران حق اشتراک بگیرند.
            مانند گروه های آموزشی، پرسش و پاسخ و سیگنال دهی و...
          </li>
          <li>
            برای فعال سازی حالت VIP لازم است که یکی از اشتراک های آبی،برنزی، نقره‌ای یا
            طلایی را خریداری کرده باشید.
          </li>
          <li>هنگام تسویه حساب درامد گروه از فروش اشتراک 25 درصد آن کسر می‌شود.</li>
          <li>
            با فعال سازی حالت VIP هر کاربری با لینک وارد گروه شود اخراج می‌شود و فقط
            کاربرانی می‌توانند وارد شوند که اشتراک خریداری کرده باشند.
          </li>
          <li>
            کاربرانی که زمان اشتراک آنها تمام شود به طور خودکار از گروه حذف می‌شوند.
          </li>
          <li>
            برای ایجاد یک گروه VIP باید تنظیمات دیگری هم در گروه انجام شود.
            <a href="https://youtu.be/aDO3ZgkAtRY" target="_blank" class="text-sky-500">
              برای آموزش ساخت یک گروه VIP میتوانید این ویدیو را در یوتیوب مشاهده کنید.
            </a>
          </li>
        </ul>
        <div class="text-center">
          <Button
            href="https://youtu.be/aDO3ZgkAtRY"
            target="_blank"
            color="red"
            class="mt-5 inline-flex"
          >
            آموزش ایجاد گروه VIP در یوتیوب
          </Button>
        </div>
        <hr class="border-blueGray-200 my-5" />
        <div
          class="p-3 bg-blueGray-50 border border-blueGray-200 rounded-md mb-5"
          dir="ltr"
        >
          <div>
            mid link:
            <span class="text-lg font-bold text-sky-600">
              https://t.me/{{ group.bot.username }}?start=group_{{ group.gid }}
            </span>
          </div>
          <div>
            VIP link:
            <span class="text-lg font-bold text-sky-600">
              {{
                res?.result?.invite_link ||
                L_.find(group.links, function (o) {
                  return o.name == "vip_link";
                })?.invite_link
              }}
            </span>
          </div>
          <Button
            @click="revokeLink"
            color="amber"
            class="h-10 mt-5 inline-flex"
            dir="rtl"
          >
            تغیر لینک VIP
          </Button>
        </div>
        <MnrCheckSlider v-model:checked="vipMod">حالت VIP</MnrCheckSlider>

        <div class="text-center">
          <Button class="mt-5 inline-flex" @click="submit">ثبت تغییرات</Button>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
import _ from "lodash";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrCheckSlider,
  },
  data() {
    return {
      firstLoading: true,
      okClass: ["border-green-400"],
      failClass: ["border-red-400"],
      group: {},
      res: false,
      vipMod: false,
      errors: [],
      L_: _,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.group = response.data.group;
          $this.vipMod = response.data.group.vip;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
          if (
            _.find($this.group.links, function (o) {
              return o.name == "vip_link";
            }) == undefined
          ) {
            $this.revokeLink();
          }
          // if (!$this.group.vipLink) {
          //   $this.revokeLink();
          // }
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    revokeLink() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get("/api/group-revoke-vip-link/" + $this.$route.params.groupId, {
          params: {
            id: $this.$store.state.user.id,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.res = response.data.res;
          // $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
    submit() {
      // console.log(update, id);
      // return;
      let $this = this;
      // $this.sending = update;
      // console.log(id, update);
      $this.errors = [];
      $this.$axios
        .put("/api/group-setting/" + $this.$route.params.groupId + "/vip", {
          id: $this.$store.state.user.id,
          vip: $this.vipMod,
        })
        .then(function (response) {
          // console.log(response);
          // $this.group = response.data.group;
          // $this.setting = response.data.setting;
          // $this.otherSetting = response.data.otherSetting;
          // $this.otherGroup = response.data.otherGroup;
          $this.$notify({ text: "تغییرات ذخیره شد", type: "success" });
        })
        .catch(function (error) {
          $this.errors = error.data;
          $this.$notify({ text: "خطا: تغییرات ذخیره نشد", type: "error" });
        })
        .then(function () {
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
